import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({ data }) {
  const page = data.allPrismicPage.edges[0].node.data
  return (
    <Layout>
      <SEO title={page.title.text} />
      <div class="pure-u-1-1">
        <h1>{page.title.text}</h1>
        <div dangerouslySetInnerHTML={{ __html: page.content.html }} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($uid: String) {
    allPrismicPage(filter: {uid: {eq: $uid} }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`